import { gsap } from "gsap"
import { Listeners } from "utils/dom"
import { o6 } from "utils/easings"

export default class ImagesSlider {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section) => {
      this.aI = 0
      this.run = false

      const wrapper = R.G.class("is-w", section)[0]
      if (!wrapper) return

      const mediasWrapper = R.G.class("m", wrapper)[0]
      const thumbsWrapper = R.G.class("th", wrapper)[0]

      const medias = R.G.class("i-s", mediasWrapper)
      const thumbs = R.G.class("i-s", thumbsWrapper)

      if (!this.run) {
        thumbs.forEach((thumb, index) => {
          if (this.aI !== index) {
            gsap.set(medias[index], { xPercent: 100 })
          }

          Listeners(thumb, "a", "click", (event) => {
            if (this.run) return

            this.run = true

            const newIndex = index
            const duration = 1

            const imageWPrev = medias[this.aI]
            const imageWNext = medias[newIndex]

            if (imageWNext.classList.contains("_is")) return

            const imageNext = R.G.class("_i", imageWNext)[0]
            const direction = index > this.aI ? 1 : -1

            imageWPrev.style.zIndex = 1
            imageWNext.style.zIndex = 2

            gsap.set(imageNext, { width: "0%" })

            if (direction === 1) {
              imageNext.style.left = "0"
              imageNext.style.right = "auto"
            } else {
              imageNext.style.left = "auto"
              imageNext.style.right = "0"
            }

            const tl = gsap.timeline()

            tl.to(imageWPrev, {
              xPercent: -50 * direction,
              duration,
              ease: o6,
              onComplete: () => {
                gsap.set(imageWPrev, { xPercent: -100 * direction })
                imageWPrev.classList.remove("_is")
                this.aI = newIndex
                imageWNext.classList.add("_is")
              },
            })

            gsap.set(imageWNext, { xPercent: 100 * direction })

            tl.to(imageWNext, { xPercent: 0, duration, ease: o6 }, 0)
            tl.to(imageNext, { width: "100%", duration, ease: o6 }, 0)

            tl.eventCallback("onComplete", () => {
              imageWNext.removeAttribute("style")
              imageNext.removeAttribute("style")

              setTimeout(() => {
                this.run = false
              }, 0)
            })
          })
        })
      }
    })
  }
}
