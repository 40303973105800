import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import { BM, Listeners, PD } from "utils/dom"
import { o3, o6, i1 } from "utils/easings"

gsap.registerPlugin(ScrollToPlugin)

export default class ConceptDesign {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["s1", "s2", "scroll", "down", "fn", "over", "out", "run"])

    R.G.class(this.item).forEach((section) => {
      const bodySection = R.G.class("cd-i", section)

      this.s = section
      const pgnW = R.G.class("cd-pgn", section)[0]
      if (!pgnW) return

      this.pgnLi = R.G.class("pgn", pgnW)
      this.w = 0
      const contentLi = R.G.class("cd-i", section)

      Listeners(window, "a", "scroll", (e) =>
        this.scroll(e, this.pgnLi, contentLi)
      )

      this.pgnLi.forEach((pgn, index) => {
        const label = R.G.class("p", pgn)[0]
        const inner = R.G.tag("span", label)[0]

        if (inner) gsap.set(inner, { xPercent: 110 })

        Listeners(pgn, "a", "click", (e) => this.down(e, index, contentLi))
      })

      const eT = ["mouseenter", "mouseleave"]

      eT.forEach((e, i) => {
        Listeners(pgnW, "a", e, this.fn)
        Listeners(pgnW, "a", e, this.fn)
      })

      this.aI0 = 0
      this.aI1 = 0
      this.run0 = false
      this.run1 = false

      bodySection.forEach((body) => {
        const sT1 = R.G.class("s1", body)[0]
        if (sT1) {
          const sT1_ThumbW = R.G.class("th", sT1)[0]
          const sT1_MediaW = R.G.class("m", sT1)[0]

          this.thumbs = R.G.class("s", sT1_ThumbW)
          this.medias = R.G.class("s", sT1_MediaW)

          this.thumbs.forEach((thumb, index) => {
            if (this.aI0 !== index) {
              gsap.set(this.medias[index], { xPercent: 100 })
            }

            if (!this.run0) {
              Listeners(thumb, "a", "click", (event) => this.s1(event, index))
            }
          })
        }

        const sT2 = R.G.class("s2", body)[0]
        if (sT2) {
          let isDragging = false
          let startX = 0
          this.currentX = 0

          const sT2_PointW = R.G.class("bi-p", sT2)[0]
          const sT2_ContentW = R.G.class("c", sT2)[0]
          this.sT2_ImageW = R.G.class("i", sT2)[0]

          this.points = R.G.class("p", sT2_PointW)
          this.images = R.G.class("ii", this.sT2_ImageW)
          this.contents = R.G.class("cc", sT2_ContentW)

          const totalImageWidth = this.sT2_ImageW.getBoundingClientRect().width

          this.maxTranslateX = 0
          this.minTranslateX = window.innerWidth - totalImageWidth

          Listeners(this.sT2_ImageW, "a", "mousedown", (e) => {
            PD(e)
            isDragging = true
            startX = e.clientX
            this.currentX = gsap.getProperty(this.sT2_ImageW, "x")
          })

          document.addEventListener("mousemove", (e) => {
            if (!isDragging) return

            const deltaX = e.clientX - startX
            let newX = this.currentX + deltaX

            newX = Math.max(
              Math.min(newX, this.maxTranslateX),
              this.minTranslateX
            )

            gsap.set(this.sT2_ImageW, { x: newX })
          })

          document.addEventListener("mouseup", () => {
            isDragging = false
          })

          this.images.forEach((image, index) => {
            if (this.aI1 !== index) {
              gsap.set(this.contents[index], { autoAlpha: 0 })
            }

            if (!this.run1) {
              const point = this.points[index]
              Listeners(image, "a", "click", (event) => this.s2(event, index))
              Listeners(point, "a", "click", (event) => this.s2(event, index))
            }
          })
        }
      })
    })
  }

  s1(event, index) {
    if (this.run0) return
    this.run0 = true

    const newIndex = index

    const duration = 1

    const imageWPrev = this.medias[this.aI0]
    const imageWNext = this.medias[newIndex]

    if (imageWNext.classList.contains("_is")) return

    const imageNext = R.G.class("_i", imageWNext)[0]

    const direction = index > this.aI0 ? 1 : -1

    imageWPrev.style.zIndex = "1"
    imageWNext.style.zIndex = "2"

    gsap.set(imageNext, { width: "0%" })

    if (direction === 1) {
      imageNext.style.left = "0"
      imageNext.style.right = "auto"
    } else {
      imageNext.style.left = "auto"
      imageNext.style.right = "0"
    }

    const tl = gsap.timeline()

    tl.to(imageWPrev, {
      xPercent: -50 * direction,
      duration,
      ease: o6,

      onComplete: () => {
        gsap.set(imageWPrev, { xPercent: -100 * direction })
        imageWPrev.classList.remove("_is")
        this.aI0 = newIndex

        imageWNext.classList.add("_is")
      },
    })

    gsap.set(imageWNext, { xPercent: 100 * direction })

    tl.to(
      imageWNext,
      {
        xPercent: 0,
        duration,
        ease: o6,
      },
      0
    )

    tl.to(
      imageNext,
      {
        width: "100%",
        duration,
        ease: o6,
      },
      0
    )

    tl.eventCallback("onComplete", () => {
      imageWNext.removeAttribute("style")
      imageNext.removeAttribute("style")

      setTimeout(() => {
        this.run0 = false
      }, 0)
    })
  }

  s2(event, index) {
    const imageNext = this.images[index]
    const imagePrev = this.images[this.aI1]
    const contentNext = this.contents[index]
    const contentPrev = this.contents[this.aI1]
    const pointNext = this.points[index]
    const pointPrev = this.points[this.aI1]

    const imageRect = imageNext.getBoundingClientRect()

    if (imageRect.left < 0 || imageRect.right > window.innerWidth) {
      let newX = gsap.getProperty(this.sT2_ImageW, "x") - imageRect.left
      newX = Math.max(Math.min(newX, this.maxTranslateX), this.minTranslateX)
      gsap.to(this.sT2_ImageW, { x: newX })
      this.currentX = newX
    }

    imageNext.classList.add("_is")
    imagePrev.classList.remove("_is")

    pointNext.classList.add("_is")
    pointPrev.classList.remove("_is")

    const tl = gsap.timeline()
    tl.to(contentPrev, {
      autoAlpha: 0,
      onComplete: () => {
        contentPrev.style.display = "none"
        contentNext.style.display = "block"
      },
    })
    tl.to(contentNext, {
      autoAlpha: 1,
    })

    this.aI1 = index
  }

  scroll(e, pgnLi, contentLi) {
    const windowTop = window.scrollY || document.documentElement.scrollTop
    let activeIndex = -1

    const sectionTop = this.s.getBoundingClientRect().top + windowTop
    const sectionBottom = sectionTop + this.s.offsetHeight

    const pgnW = R.G.class("cd-pgn", this.s)[0]

    if (windowTop >= sectionTop && windowTop <= sectionBottom) {
      pgnW.classList.add("fixed")
    } else {
      pgnW.classList.remove("fixed")
    }

    contentLi.forEach((item, i) => {
      const banner = R.G.class("cd-h", item)[0]
      const bannerTopViewport = banner.getBoundingClientRect().top
      const bannerTopDocument = bannerTopViewport + windowTop

      if (
        windowTop >= bannerTopDocument &&
        (i + 1 === contentLi.length ||
          windowTop <
            R.G.class("cd-h", contentLi[i + 1])[0].getBoundingClientRect().top +
              windowTop)
      ) {
        activeIndex = i
      }
    })

    pgnLi.forEach((pgn, index) => {
      if (index === activeIndex) {
        pgn.classList.add("_is")
      } else {
        pgn.classList.remove("_is")
      }
    })
  }

  down(e, index, contentLi) {
    const target = contentLi[index]
    const targetTop = target.getBoundingClientRect().top

    const scrollPosition = targetTop + window.scrollY + 50

    if (target) {
      gsap.to(window, {
        scrollTo: { y: scrollPosition },
        ease: o3,
        duration: 1,
      })
    }
  }

  fn(e) {
    this.event = e
    this.eventType = e.type
    this.eventTarget = e.currentTarget

    this.run()
  }

  run() {
    if (this.eventType === "mouseenter") {
      this.over()
    } else if (this.eventType === "mouseleave") {
      this.out()
    }
  }

  over() {
    this.pgnLi.forEach((pgn, index) => {
      const label = R.G.class("p", pgn)[0]
      const inner = R.G.tag("span", label)[0]

      gsap.to(inner, {
        xPercent: 0,
        duration: 0.8,
        ease: o6,
      })
    })
  }

  out() {
    this.pgnLi.forEach((pgn, index) => {
      const label = R.G.class("p", pgn)[0]
      const inner = R.G.tag("span", label)[0]

      gsap.to(inner, {
        xPercent: 110,
        duration: 0.6,
        ease: i1,
      })
    })
  }
}
