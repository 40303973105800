import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { BM, Listeners } from "utils/dom"

gsap.registerPlugin(ScrollTrigger)

export default class PressList {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["popO", "popC"])

    this.activePop = null

    R.G.class(this.item).forEach((section) => {
      const ul = R.G.class("pr-ul", section)[0]
      const li = R.G.class("pr-li", ul)

      li.forEach((i) => {
        if (i.getAttribute("data-t") === "v") {
          Listeners(i, "a", "click", this.popO)
        }
      })
    })
  }

  popO(event) {
    const target = event.currentTarget
    const id = target.getAttribute("data-s")

    const popW = R.G.class("pr-v", "pr")[0]
    const pops = R.G.class("pr-v-i", popW)

    const pop = Array.from(pops).find(
      (popup) => popup.getAttribute("data-s") === id
    )

    if (!popW.classList.contains("_is")) {
      this.activePop = pop

      const cl = R.G.class("cl", this.activePop)[0]
      Listeners(cl, "a", "click", this.popC)

      _A.e.le.stop()

      gsap.set(popW, {
        zIndex: 999,
        pointerEvents: "auto",
        onComplete: () => {
          popW.classList.add("_is")
        },
      })

      gsap.to(pop, {
        autoAlpha: 1,
        onComplete: () => {
          R.G.tag("video", pop)[0].play()
        },
      })
    }
  }

  popC(event) {
    const popW = R.G.class("pr-v", "pr")[0]

    const videos = R.G.tag("video", this.activePop)
    videos.forEach((video) => video.pause())

    gsap.to(this.activePop, {
      autoAlpha: 0,
    })

    gsap.set(popW, {
      zIndex: -999,
      pointerEvents: "none",
      onComplete: () => {
        if (popW.classList.contains("_is")) popW.classList.remove("_is")
        this.activePop = null
      },
    })

    _A.e.le.start()
  }
}
