export const PD = (event) => {
  event.cancelable && event.preventDefault()
}

export const GetElement = {
  id: (id, el = document) => el.getElementById(id),
  class: (cl, el = document) => {
    const elements = (
      (el && el.getElementsByClassName && el) ||
      document
    ).getElementsByClassName(cl)
    return [...elements].filter((el) => el instanceof HTMLElement)
  },
  tag: (tag, el = document) => {
    const elements = (
      (el && el.getElementsByTagName && el) ||
      document
    ).getElementsByTagName(tag)
    return [...elements].filter((el) => el instanceof HTMLElement)
  },
}

export const Is = {
  str: (value) => typeof value === "string",
  obj: (value) => value === Object(value),
  arr: (value) => value.constructor === Array,
  def: (value) => value !== undefined,
  und: (value) => value === undefined,
}

export function BM(object, methodNames) {
  const numMethods = methodNames.length
  for (let i = 0; i < numMethods; i++) {
    const methodName = methodNames[i]
    object[methodName] = object[methodName].bind(object)
  }
}

export const Listeners = (element, action, eventCallback, useCapture) => {
  const doc = document
  let elements = Select.el(element) ?? document
  const numElements = elements.length
  let actualEvent = eventCallback

  const commonEventNames = [
    "mousemove",
    "mousewheel",
    "touchmove",
    "touchstart",
  ]
  const options = commonEventNames.includes(eventCallback)
    ? { passive: false }
    : undefined

  if (eventCallback === commonEventNames[0]) {
    actualEvent =
      "onwheel" in doc
        ? "wheel"
        : Is.def(doc.onmousewheel)
        ? "mousewheel"
        : "DOMMouseScroll"
  } else if (eventCallback === "focusOut") {
    actualEvent = isFirefox() ? "blur" : "focusout"
  }

  const actionType = action === "a" ? "add" : "remove"

  for (let i = 0; i < numElements; i++) {
    elements[i][actionType + "EventListener"](actualEvent, useCapture, options)
  }
}

export const Select = {
  el: (t) => {
    if (t instanceof Element || t === window || t === document) {
      return [t]
    }

    if (Is.str(t)) {
      const selectorType = t.charAt(0)
      const selectorName = t.substring(1)
      if (selectorType === "#") {
        return [GetElement.id(selectorName)]
      } else if (selectorType === ".") {
        return GetElement.class(selectorName)
      }
    }

    return []
  },
  type: (t) => (t.charAt(0) === "#" ? "id" : "class"),
  name: (t) => t.substring(1),
}
