import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import { BM, Listeners } from "utils/dom"
import { o3, o6, i1 } from "utils/easings"

gsap.registerPlugin(ScrollToPlugin)

export default class BoatBuilding {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["scroll", "down", "fn"])

    R.G.class(this.item).forEach((section) => {
      if (!section) return

      this.s = section
      const pgnW = R.G.class("mb-pgn", section)[0]
      this.pgnLi = R.G.class("pgn", pgnW)
      this.w = 0
      const contentLi = R.G.class("mb-i", section)

      Listeners(window, "a", "scroll", (e) =>
        this.scroll(e, this.pgnLi, contentLi)
      )

      this.pgnLi.forEach((pgn, index) => {
        const label = R.G.class("p", pgn)[0]
        const inner = R.G.tag("span", label)[0]

        if (inner) gsap.set(inner, { xPercent: 110 })

        Listeners(pgn, "a", "click", (e) => this.down(e, index, contentLi))
      })

      const eT = ["mouseenter", "mouseleave"]

      eT.forEach((e, i) => {
        Listeners(pgnW, "a", e, this.fn)
        Listeners(pgnW, "a", e, this.fn)
      })
    })
  }

  fn(e) {
    this.event = e
    this.eventType = e.type
    this.eventTarget = e.currentTarget

    this.run()
  }

  run() {
    if (this.eventType === "mouseenter") {
      this.over()
    } else if (this.eventType === "mouseleave") {
      this.out()
    }
  }

  over() {
    this.pgnLi.forEach((pgn, index) => {
      const label = R.G.class("p", pgn)[0]
      const inner = R.G.tag("span", label)[0]

      gsap.to(inner, {
        xPercent: 0,
        duration: 0.8,
        ease: o6,
      })
    })
  }

  out() {
    this.pgnLi.forEach((pgn, index) => {
      const label = R.G.class("p", pgn)[0]
      const inner = R.G.tag("span", label)[0]

      gsap.to(inner, {
        xPercent: 110,
        duration: 0.6,
        ease: i1,
      })
    })
  }

  scroll(e, pgnLi, contentLi) {
    const windowTop = window.scrollY || document.documentElement.scrollTop
    let activeIndex = -1

    const sectionTop = this.s.getBoundingClientRect().top + windowTop
    const sectionBottom = sectionTop + this.s.offsetHeight

    const pgnW = R.G.class("mb-pgn", this.s)[0]

    if (windowTop >= sectionTop && windowTop <= sectionBottom) {
      pgnW.classList.add("fixed")
    } else {
      pgnW.classList.remove("fixed")
    }

    contentLi.forEach((item, i) => {
      const banner = R.G.class("mb-h", item)[0]
      const bannerTopViewport = banner.getBoundingClientRect().top
      const bannerTopDocument = bannerTopViewport + windowTop

      if (
        windowTop >= bannerTopDocument &&
        (i + 1 === contentLi.length ||
          windowTop <
            R.G.class("mb-h", contentLi[i + 1])[0].getBoundingClientRect().top +
              windowTop)
      ) {
        activeIndex = i
      }
    })

    pgnLi.forEach((pgn, index) => {
      if (index === activeIndex) {
        pgn.classList.add("_is")
      } else {
        pgn.classList.remove("_is")
      }
    })
  }

  down(e, index, contentLi) {
    const target = contentLi[index]
    const targetTop = target.getBoundingClientRect().top

    const scrollPosition = targetTop + window.scrollY + 50

    if (target) {
      gsap.to(window, {
        scrollTo: { y: scrollPosition },
        ease: o3,
        duration: 1,
      })
    }
  }
}
