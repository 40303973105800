import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import { BM, Listeners } from "utils/dom"
import { o6 } from "utils/easings"

gsap.registerPlugin(ScrollToPlugin)

export default class FAQ {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["down", "scroll", "pgnD"])

    R.G.class(this.item).forEach((section) => {
      const faqSection = R.G.class("faq-s", section)
      const itemsLi = R.G.class("i", faqSection)
      const faqPgnC = R.G.class("faq-pgn", section)[0]
      const faqPgn = R.G.class("faq-pgn-w", section)[0]
      const pgnLi = R.G.class("pgn", faqPgn)

      this.contents = []
      this.s = false

      itemsLi.forEach((i) => {
        const title = R.G.class("i-h", i)[0]
        const description = R.G.class("i-d", i)[0]
        const tgl = R.G.class("tog", title)[0]

        const contentObject = { title, description, tgl, isOpen: false }
        this.contents.push(contentObject)

        gsap.set(description, { height: 0, overflow: "hidden" })

        Listeners(title, "a", "click", (e) => this.down(e, contentObject))
      })

      Listeners(window, "a", "scroll", (e) =>
        this.scroll(e, pgnLi, faqSection, section)
      )

      pgnLi.forEach((pgn, index) => {
        Listeners(pgn, "a", "click", (e) => this.pgnD(e, index, faqSection))
      })
    })
  }

  scroll(e, pgnLi, faqSection, section) {
    const windowTop = window.scrollY || document.documentElement.scrollTop
    const windowBottom = windowTop + window.innerHeight
    const windowMiddle = windowTop + window.innerHeight / 2
    let activeIndex = -1

    const sectionTop = section.getBoundingClientRect().top + windowTop
    const sectionBottom = sectionTop + section.offsetHeight

    if (windowTop >= sectionTop && windowBottom <= sectionBottom) {
      R.G.class("faq-pgn", section)[0].classList.add("fixed")
    } else {
      R.G.class("faq-pgn", section)[0].classList.remove("fixed")
    }

    faqSection.forEach((item, i) => {
      const title = R.G.class("i-h", item)[0]
      const titleTopViewport = title.getBoundingClientRect().top
      const titleTopDocument = titleTopViewport + windowTop

      const titleHeight = title.offsetHeight
      const titleMiddle = titleTopDocument + titleHeight / 2

      if (
        windowMiddle >= titleMiddle &&
        (i + 1 === faqSection.length ||
          windowMiddle <
            R.G.class("i-h", faqSection[i + 1])[0].getBoundingClientRect().top +
              windowTop)
      ) {
        activeIndex = i
      }
    })

    pgnLi.forEach((pgn, index) => {
      if (index === activeIndex) {
        pgn.classList.add("_is")
      } else {
        pgn.classList.remove("_is")
      }
    })
  }

  pgnD(e, index, faqSection) {
    const target = faqSection[index]
    const targetTop = target.getBoundingClientRect().top
    const targetHeight = target.getBoundingClientRect().height
    const windowHeight = window.innerHeight

    const scrollPosition =
      targetTop + window.scrollY - windowHeight / 2 + targetHeight / 2

    if (target) {
      gsap.to(window, {
        scrollTo: { y: scrollPosition },
        ease: o6,
        duration: 1,
      })
    }
  }

  down(event, clickedContent) {
    const targetParent = event.currentTarget.parentElement
    let isOpenedContentClicked = false

    this.contents.forEach((contentObject) => {
      if (contentObject.isOpen) {
        gsap.to(contentObject.description, {
          height: 0,
          overflow: "hidden",
          duration: 0.3,
        })

        gsap.to(contentObject.tgl, {
          rotation: 0,
          transformOrigin: "center center",
        })

        contentObject.isOpen = false

        if (contentObject === clickedContent) {
          isOpenedContentClicked = true
        }
      }
    })

    if (!isOpenedContentClicked) {
      gsap.to(clickedContent.description, {
        height: "auto",
        overflow: "hidden",
        duration: 0.3,
      })

      gsap.to(clickedContent.tgl, {
        rotation: 45,
        transformOrigin: "center center",
      })

      clickedContent.isOpen = true
    }
  }
}
