import GSAP from "gsap"
import { Listeners } from "utils/dom"

export default class Prices {
  constructor(item) {
    this.item = item
  }
  init(item) {
    if (!this.item) this.item = item

    let slideIndex = 0
    let isAnimating = false

    const slides = Array.from(R.G.class("pcs-ul-w", this.item))
    const pgnW = R.G.class("pcs-pgn", this.item)[0]
    const pgn = R.G.class("pgn", pgnW)

    const changeActivePrices = () => {
      isAnimating = true // set animation state to true at start of animation
      slides.forEach((slide, index) => {
        if (slide.classList.contains("_is")) {
          GSAP.to(slide, {
            autoAlpha: 0,
            duration: 0.3,
            onComplete: () => {
              slide.classList.remove("_is")
              pgn[index].classList.remove("_is")
            },
          })
        }

        if (index === slideIndex) {
          GSAP.to(slide, {
            autoAlpha: 1,
            duration: 0.3,
            delay: 0.299,
            onStart: () => {
              slide.classList.add("_is")
              pgn[index].classList.add("_is")
            },
            onComplete: () => {
              isAnimating = false
            },
          })
        }
      })
    }

    pgn.forEach((pagination, index) => {
      Listeners(pagination, "a", "click", (e) => {
        if (isAnimating) return
        slideIndex = index
        changeActivePrices()
      })
    })

    changeActivePrices()
  }
}
