import GSAP from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CustomEase } from "gsap/CustomEase"

import { GetElement, Is } from "utils/dom"

import Hero from "components/Hero/d"
import BoatBuilding from "components/BoatBuilding/d"
import TwoColumns from "components/TwoColumns/d"
import ScrollGallery from "components/ScrollGallery/d"
import Testimonial from "components/Testimonial/d"
import ImagesSlider from "components/ImagesSlider/d"
import ProductSpec from "components/ProductSpec/d"
import HalfImageSlider from "components/HalfImageSlider/d"
import HalfImageSlider2 from "components/HalfImageSlider2/d"
import ProductDesc from "components/ProductDesc/d"
import FullWidthImage from "components/FullWidthImage/d"
import FullImageGallery from "components/FullImageGallery/d"
import FullVideoGallery from "components/FullVideoGallery/d"
import Timeline from "components/Timeline/d"
import Teams from "components/Teams/d"
import FAQ from "components/FAQ/d"
import PressList from "components/PressList/d"
import PressHighlightSlider from "components/PressHighlightSlider/d"
import Prices from "components/Prices/d"
import Magazines from "components/Magazines/d"
import MagazinesList from "components/MagazinesList/d"
import ConceptDesign from "components/ConceptDesign/d"
import BBIntro from "components/BBIntro/d"
import ManagementBranding from "components/ManagementBranding/d"
import MasonryGallery from "components/MasonryGallery/d"

_A.co.blocks = []

window.R = {}
R.G = GetElement
R.Is = Is

const _b = [
  { n: "hr", f: Hero },
  { n: "fwi", f: FullWidthImage },
  { n: "bb", f: BBIntro },
  { n: "bb2", f: BoatBuilding },
  { n: "tc2", f: TwoColumns },
  { n: "sg", f: ScrollGallery },
  { n: "te", f: Testimonial },
  { n: "is", f: ImagesSlider },
  { n: "ps", f: ProductSpec },
  { n: "ha", f: HalfImageSlider },
  { n: "ha1", f: HalfImageSlider2 },
  { n: "pd", f: ProductDesc },
  { n: "ti", f: Timeline },
  { n: "tm", f: Teams },
  { n: "fg", f: FullVideoGallery },
  { n: "fg1", f: FullImageGallery },
  { n: "faq", f: FAQ },
  { n: "pr", f: PressList },
  { n: "prh", f: PressHighlightSlider },
  { n: "pcs", f: Prices },
  { n: "ma", f: Magazines },
  { n: "ma2", f: MagazinesList },
  { n: "cd", f: ConceptDesign },
  { n: "mb", f: ManagementBranding },
  { n: "mas", f: MasonryGallery },
]

GSAP.registerPlugin(ScrollTrigger)
GSAP.registerPlugin(CustomEase)

_b.forEach(({ n, f }) => {
  _A.co.blocks.push({ n, f: new f(n) })
})
