import GSAP from "gsap"
import { BM, Listeners } from "utils/dom"

export default class ProductSpec {
  constructor(item) {
    if (!item) return
    this.item = item

    BM(this, ["down"])
  }

  init() {
    const li = R.G.class("ps-sp", this.item)
    this.contents = []

    li.forEach((i) => {
      const title = R.G.class("ps-sp-t", i)[0]
      const content = R.G.class("ps-sp-d", i)[0]
      const tgl = R.G.class("tgl-v", i)[0]

      const contentObject = { content, tgl, isOpen: false }
      this.contents.push(contentObject)

      GSAP.set(content, { height: 0, overflow: "hidden" })

      Listeners(title, "a", "click", (event) => this.down(event, contentObject))
    })
  }

  down(event, clickedContent) {
    let isOpenedContentClicked = false

    this.contents.forEach((contentObject) => {
      if (contentObject.isOpen) {
        GSAP.to(contentObject.content, {
          height: 0,
          overflow: "hidden",
          duration: 0.3,
        })

        GSAP.to(contentObject.tgl, {
          opacity: 1,
          duration: 0.3,
        })

        contentObject.isOpen = false

        if (contentObject === clickedContent) {
          isOpenedContentClicked = true
        }
      }
    })

    if (!isOpenedContentClicked) {
      GSAP.to(clickedContent.content, {
        height: "auto",
        overflow: "hidden",
        duration: 0.3,
      })

      GSAP.to(clickedContent.tgl, {
        opacity: 0,
        duration: 0.3,
      })

      clickedContent.isOpen = true
    }
  }
}
