import GSAP from "gsap"
import { Listeners, BM } from "utils/dom"

export default class TwoColumns {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    const wrapper = R.G.class("tc2-w", this.item)

    this.c1 = wrapper[0].children[0]
    this.c2 = wrapper[0].children[1]

    BM(this, ["fn", "over", "out"])

    const eT = ["mouseenter", "mouseleave", "click"]

    GSAP.set(this.c1, { width: "50%" })
    GSAP.set(this.c2, { width: "50%" })

    eT.forEach((e, i) => {
      Listeners(this.c1, "a", e, this.fn)
      Listeners(this.c2, "a", e, this.fn)
    })
  }

  fn(e) {
    this.event = e
    this.eventType = e.type
    this.eventTarget = e.currentTarget

    this.run()
  }

  run() {
    if (this.eventType === "mouseenter") {
      this.over()
    } else if (this.eventType === "mouseleave") {
      this.out()
    } else {
      console.log("click")
    }
  }

  over() {
    const tl = GSAP.timeline({ defaults: { duration: 0.5, overwrite: "auto" } })
    if (this.eventTarget === this.c1) {
      tl.to(this.c1, { width: "60%" }, 0).to(this.c2, { width: "40%" }, 0)
    } else {
      tl.to(this.c1, { width: "40%" }, 0).to(this.c2, { width: "60%" }, 0)
    }
  }

  out() {
    const tl = GSAP.timeline({ defaults: { duration: 0.5, overwrite: "auto" } })
    tl.to(this.c1, { width: "50%" }, 0).to(this.c2, { width: "50%" }, 0)
  }
}
