import GSAP from "gsap"
import { Listeners } from "utils/dom"

export default class Testimonial {
  constructor(item) {
    this.item = item
  }
  init(item) {
    if (!this.item) this.item = item

    let slideIndex = 0
    let isAnimating = false // flag to track animation state
    const testimonials = Array.from(R.G.class("te-s", this.item))
    const nav = R.G.class("te-n")[0]

    const changeActiveTestimonial = () => {
      isAnimating = true // set animation state to true at start of animation
      testimonials.forEach((testimonial, index) => {
        if (testimonial.classList.contains("iss")) {
          GSAP.to(testimonial, {
            autoAlpha: 0,
            duration: 0.3,
            onComplete: () => {
              testimonial.classList.remove("iss")
            },
          })
        }

        if (index === slideIndex) {
          GSAP.to(testimonial, {
            autoAlpha: 1,
            duration: 0.3,
            delay: 0.299,
            onStart: () => {
              testimonial.classList.add("iss")
            },
            onComplete: () => {
              isAnimating = false // set animation state to false at end of animation
            },
          })
        }
      })
    }

    Listeners(R.G.class("te-n-a", nav)[0], "a", "click", (e) => {
      if (isAnimating) return // if animation is ongoing, ignore clicks
      slideIndex--
      if (slideIndex < 0) {
        slideIndex = testimonials.length - 1
      }
      changeActiveTestimonial()
    })

    Listeners(R.G.class("te-n-a", nav)[1], "a", "click", (e) => {
      if (isAnimating) return // if animation is ongoing, ignore clicks
      slideIndex++
      if (slideIndex >= testimonials.length) {
        slideIndex = 0
      }
      changeActiveTestimonial()
    })

    changeActiveTestimonial()
  }
}
