import GSAP from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

GSAP.registerPlugin(ScrollTrigger)

export default class FullWidthImage {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((el) => {
      if (!el.classList.contains("z")) return

      const picture = R.G.tag("picture", el)[0]

      GSAP.to(picture, {
        duration: 3,
        scale: 1.18,
        ease: "linear",
        scrollTrigger: {
          trigger: el,
          scrub: true,
          start: "top top",
          end: "bottom top",
        },
      })
    })
  }
}
