import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { Listeners } from "utils/dom"

gsap.registerPlugin(ScrollTrigger)

export default class ScrollGallery {
  constructor(item) {
    this.item = item
  }
  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section, idx) => {
      this.targets = gsap.utils.toArray(R.G.class("s", section))
      let direction = 1

      Listeners(window, "a", "scroll", () => {
        direction = _A.e.le.direction
      })

      this.targets.forEach((target) => {
        const bgW = R.G.class("sg-bg", target)[0]
        const bgI = R.G.tag("img", bgW)[0]
        const w1 = R.G.class("w-1", target)[0]
        const sgw = R.G.class("g-w", target)[0]

        gsap.set(bgI, { scale: 1.15 })
        gsap.fromTo(
          bgI,
          { yPercent: 2 },
          {
            yPercent: -2,
            ease: "none",
            scrollTrigger: {
              trigger: bgW,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        )

        gsap.fromTo(
          sgw,
          {
            yPercent: 20 * direction,
          },
          {
            yPercent: 0,
            scrollTrigger: {
              trigger: w1,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        )
      })

      if (idx === R.G.class(this.item).length - 1) {
        ScrollTrigger.refresh()
      }
    })
  }
}
