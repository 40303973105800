import GSAP from "gsap"

import { Listeners, BM } from "utils/dom"
import { o4 } from "utils/easings"

export default class Teams {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["click"])

    const teamsList = document.querySelectorAll("[data-content-id]")
    const popupWrapper = R.G.class("tm-pop-w")[0]

    if (!teamsList || !popupWrapper) return

    GSAP.set(popupWrapper, { yPercent: 110 })

    teamsList.forEach((team) => {
      Listeners(team, "a", "click", this.click)
    })
  }

  click(event) {
    event.preventDefault()

    _A.pop = true
    let isNavScrollUp = null

    if (!_A.nav.classList.contains("_is")) {
      isNavScrollUp = true
      _A.nav.classList.add("_is")
    }

    const target = event.currentTarget
    const targetID = target.getAttribute("data-content-id")
    const { innerHTML } = R.G.id(targetID)

    const popupContainer = R.G.class("tm-pop", this.item)[0]
    const popupWrapper = R.G.class("tm-pop-w", popupContainer)[0]
    const popupOverlay = R.G.class("tm-pop-o", popupContainer)[0]
    const popupContent = R.G.class("tm-pop-wi", popupContainer)[0]

    popupContent.innerHTML = innerHTML

    const tl = GSAP.timeline()
    popupContainer.style.display = "block"

    _A.e.le.stop()

    tl.to(popupOverlay, { autoAlpha: 1, duration: 1 })
    tl.fromTo(
      popupWrapper,
      { yPercent: 110 },
      { yPercent: 0, duration: 1 },
      0.5
    )

    Listeners(
      R.G.class("tm-pop-close", popupContainer)[0],
      "a",
      "click",
      () => {
        const tl = GSAP.timeline()
        tl.fromTo(
          popupWrapper,
          { yPercent: 0 },
          { yPercent: 110, duration: 1, ease: o4 }
        )
        tl.to(
          popupOverlay,
          {
            autoAlpha: 0,
            duration: 0.5,
            onComplete: () => {
              popupContainer.style.display = "none"
              _A.pop = false

              if (isNavScrollUp) {
                _A.nav.classList.remove("_is")
              }

              _A.e.le.start()
            },
          },
          0.5
        )
      }
    )

    Listeners(R.G.class("tm-pop-o", popupContainer)[0], "a", "click", () => {
      const tl = GSAP.timeline()
      tl.fromTo(
        popupWrapper,
        { yPercent: 0 },
        { yPercent: 110, duration: 1, ease: o4 }
      )
      tl.to(
        popupOverlay,
        {
          autoAlpha: 0,
          duration: 0.5,
          onComplete: () => {
            popupContainer.style.display = "none"
            _A.pop = false

            if (isNavScrollUp) {
              _A.nav.classList.remove("_is")
            }

            _A.e.le.start()
          },
        },
        0.5
      )
    })
  }
}
