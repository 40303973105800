import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class BBIntro {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section, idx) => {
      const fwi = R.G.class("bb-fwi", section)[0]
      const fwi0 = R.G.class("bb-fwi-0", fwi)[0]
      const fwi1 = R.G.class("bb-fwi-1", fwi)[0]

      const w = R.G.class("bb-fwi-w", fwi)[0]

      if (!fwi0) return

      gsap.set(fwi0, { scale: 0.7 })
      gsap.to(fwi0, {
        duration: 3,
        scale: 1,
        ease: "linear",
        scrollTrigger: {
          trigger: w,
          scrub: true,
          start: "top 80%",
          end: "center 60%",
        },
      })

      const bbTL = gsap.timeline({
        defaults: {
          duration: 5,
          ease: "linear",
        },
        scrollTrigger: {
          trigger: w,
          scrub: true,
          start: "center center",
          end: "bottom",
        },
      })

      bbTL.fromTo(
        fwi1,
        { clipPath: "inset(100% 0px 0px 0px)" },
        { clipPath: "inset(0% 0px 0px 0px)" }
      )

      if (idx === R.G.class(this.item).length - 1) {
        setTimeout(() => {
          ScrollTrigger.refresh()
        }, 1000)
      }
    })
  }
}

// import GSAP from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"

// import { Listeners } from "utils/dom"

// export default class BBIntro {
//   constructor(item) {
//     this.item = item
//   }

//   init(item) {
//     if (!this.item) this.item = item

//     R.G.class(this.item).forEach((section, idx) => {
//       setTimeout(() => {
//         const fwi = R.G.class("bb-fwi", section)[0]
//         const fwiW = R.G.class("bb-fwi-w", section)[0]
//         const fwi0 = R.G.class("bb-fwi-0", fwi)[0]
//         const fwi1 = R.G.class("bb-fwi-1", fwi)[0]
//         const hoverW = R.G.class("hov", fwi1)[0]

//         const mainImage = R.G.class("_m", fwi1)[0]
//         const hovers = R.G.class("_i", hoverW)
//         const textW = R.G.class("bb-fwi-c", section)[0]
//         const texts = R.G.tag("li", textW)

//         const w = R.G.class("bb-fwi-w", fwi)[0]

//         if (!fwi0) return

//         GSAP.set(fwi0, { scale: 0.7 })
//         GSAP.to(fwi0, {
//           duration: 3,
//           scale: 1,
//           ease: "linear",
//           scrollTrigger: {
//             trigger: fwiW,
//             scrub: true,
//             start: "top 80%",
//             end: "center 60%",
//           },
//         })

//         const bbTL = GSAP.timeline({
//           defaults: {
//             duration: 5,
//             ease: "linear",
//           },
//           scrollTrigger: {
//             trigger: w,
//             scrub: true,
//             start: "center center",
//             end: "bottom",
//           },
//         })

//         bbTL.fromTo(
//           fwi1,
//           { clipPath: "inset(100% 0px 0px 0px)" },
//           { clipPath: "inset(0% 0px 0px 0px)" }
//         )

//         texts.forEach((text, index) => {
//           Listeners(text, "a", "mouseover", (e) => {
//             GSAP.to(hovers[index], {
//               opacity: 1,
//               duration: 0,
//             })

//             GSAP.to(mainImage, {
//               opacity: 0,
//               duration: 0,
//             })
//           })
//           Listeners(text, "a", "mouseout", (e) => {
//             GSAP.to(hovers, {
//               opacity: 0,
//               duration: 0,
//             })

//             GSAP.to(mainImage, {
//               opacity: 1,
//               duration: 0,
//             })
//           })
//         })
//         console.log("test")
//       }, 1000)

//       if (idx === R.G.class(this.item).length - 1) {
//         setTimeout(() => {
//           console.log("test2")
//           ScrollTrigger.refresh()
//         }, 1000)
//       }
//     })
//   }
// }
