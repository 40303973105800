import { gsap } from "gsap"
import { BM, Listeners } from "utils/dom"

export default class PressHighlightSlider {
  constructor(item) {
    this.item = item
  }
  init(item) {
    if (!this.item) this.item = item

    this.aI = 0
    this.activePop = null

    BM(this, ["fn", "popO", "popC"])

    R.G.class(this.item).forEach((section) => {
      const logosW = R.G.class("prh-s-l", section)[0]
      const mediasW = R.G.class("prh-s-bg", section)[0]
      const contentsW = R.G.class("prh-s-c", section)[0]

      const logos = R.G.class("prh-s-l-i", logosW)
      const medias = R.G.class("prh-s-bg-i", mediasW)
      const contents = R.G.class("prh-s-c-i", contentsW)

      gsap.set(medias[0], { zIndex: 2 })

      const navW = R.G.class("prh-p", section)[0]
      const next = R.G.class("p-n", navW)[0]
      const prev = R.G.class("p-p", navW)[0]

      let maxHeight = 0

      contents.forEach((content, index) => {
        const height = content.offsetHeight
        if (height > maxHeight) maxHeight = height
        if (index === contents.length - 1)
          contentsW.style.height = `${maxHeight}px`
      })

      medias.forEach((i, idx) => {
        if (idx !== 0) {
          gsap.set(i, { xPercent: 100 })
        } else {
          gsap.set(i, { xPercent: 0 })
        }
        if (i.getAttribute("data-t") === "v") {
          Listeners(i, "a", "click", this.popO)
        }
      })

      Listeners(prev, "a", "click", (e) => this.fn(e, logos, medias, contents))
      Listeners(next, "a", "click", (e) => this.fn(e, logos, medias, contents))
    })
  }

  popO(event) {
    const target = event.currentTarget
    const id = target.getAttribute("data-s")

    const popW = R.G.class("prh-v", "prh")[0]
    const pops = R.G.class("prh-v-i", popW)

    const pop = Array.from(pops).find(
      (popup) => popup.getAttribute("data-s") === id
    )

    if (!popW.classList.contains("_is")) {
      this.activePop = pop

      const cl = R.G.class("cl", this.activePop)[0]
      Listeners(cl, "a", "click", this.popC)

      _A.e.le.stop()

      gsap.set(popW, {
        zIndex: 999,
        pointerEvents: "auto",
        onComplete: () => {
          popW.classList.add("_is")
        },
      })

      gsap.to(pop, {
        autoAlpha: 1,
        onComplete: () => {
          R.G.tag("video", pop)[0].play()
        },
      })
    }
  }

  popC(event) {
    const popW = R.G.class("prh-v", "pr")[0]

    const videos = R.G.tag("video", this.activePop)
    videos.forEach((video) => video.pause())

    const tl = gsap.timeline()
    tl.to(this.activePop, {
      autoAlpha: 0,
    })

    tl.set(popW, {
      zIndex: -999,
      pointerEvents: "none",
      onComplete: () => {
        if (popW.classList.contains("_is")) popW.classList.remove("_is")
        this.activePop = null
      },
    })

    _A.e.le.start()
  }

  off(next, prev) {
    next.setAttribute("disabled", "disabled")
    prev.setAttribute("disabled", "disabled")
    next.style.pointerEvents = "none"
    prev.style.pointerEvents = "none"
  }

  on(next, prev) {
    next.removeAttribute("disabled")
    prev.removeAttribute("disabled")
    next.style.pointerEvents = "auto"
    prev.style.pointerEvents = "auto"
  }

  fn(event, logos, medias, contents) {
    const target = event.currentTarget
    const direction = target.classList.contains("p-p") ? -1 : 1

    let nextIndex = this.aI + direction

    if (nextIndex >= medias.length) {
      nextIndex = 0
    } else if (nextIndex < 0) {
      nextIndex = medias.length - 1
    }

    const navW = target.closest(".prh-p")
    const next = R.G.class("p-n", navW)[0]
    const prev = R.G.class("p-p", navW)[0]

    this.off(next, prev)

    const tl = gsap.timeline({
      onComplete: () => {
        this.on(next, prev)
      },
    })

    if (direction > 0) {
      gsap.set(medias[nextIndex], {
        xPercent: 100,
      })

      tl.to(medias[this.aI], { xPercent: -100 }).to(
        medias[nextIndex],
        { xPercent: 0 },
        0
      )
    } else {
      gsap.set(medias[nextIndex], {
        xPercent: -100,
      })

      tl.to(medias[this.aI], { xPercent: 100 }).to(
        medias[nextIndex],
        { xPercent: 0 },
        0
      )
    }

    logos[this.aI].classList.remove("_is")
    medias[this.aI].classList.remove("_is")
    contents[this.aI].classList.remove("_is")

    this.aI = nextIndex

    logos[nextIndex].classList.add("_is")
    medias[nextIndex].classList.add("_is")
    contents[nextIndex].classList.add("_is")
  }
}
