import { gsap } from "gsap"

import { Listeners } from "utils/dom"

import Isotope from "utils/iso"

export default class MasonryGallery {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section) => {
      if (!section) return

      const imagesWrapper = R.G.class("mas-m", section)[0]
      const images = R.G.class("mas-m-i", imagesWrapper)

      const categoriesWrapper = R.G.class("mas-c", section)[0]
      const categories = R.G.class("mas-c-i", categoriesWrapper)

      let activeI = 0

      let iso = new Isotope(imagesWrapper, {
        itemSelector: ".mas-m-i",
        layoutMode: "fitRows",
      })

      const popup = R.G.class("mas-pop")[0]
      const popupI = R.G.tag("img", popup)[0]
      const popupC = R.G.id("mp-t")

      popup.style.opacity = 0
      popup.style.visibility = "hidden"

      const popupPrev = R.G.id("mp-p")
      const popupNext = R.G.id("mp-n")

      let currentIdx = 0

      const setPopupImageClass = () => {
        popupI.classList.remove("p", "l", "l2", "o")
        popupI.onload = () => {
          const aspectRatio = popupI.naturalWidth / popupI.naturalHeight

          if (popupI.naturalHeight > popupI.naturalWidth) {
            popupI.classList.add("p")
          } else if (aspectRatio === 1) {
            popupI.classList.add("o")
          } else if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
            popupI.classList.add("l2")
          } else if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
            popupI.classList.add("p2")
          } else {
            popupI.classList.add("l")
          }
        }
      }

      images.forEach((image, idx) => {
        Listeners(
          image,
          "a",
          "click",
          this.debounce((e) => {
            e.stopImmediatePropagation()
            const title = image.getAttribute("data-t")
            const el = R.G.tag("img", image)[0]
            const imgSrc = el.src

            popupI.src = imgSrc
            popupC.innerText = title

            setPopupImageClass()
            currentIdx = idx

            gsap.to(popup, {
              autoAlpha: 1,
              duration: 0.3,
              onStart: () => {
                popup.classList.add("_is")

                gsap.to(_A.nav, {
                  opacity: 0,
                  pointerEvents: "none",
                  duration: 0.1,
                })

                _A.e.le.stop()
              },
            })
          }),
          600
        )
      })

      const animateImageChange = (newSrc, newTitle) => {
        gsap.to(popupI, {
          opacity: 0,
          duration: 0.3,
          onComplete: function () {
            popupI.src = newSrc
            popupC.innerText = newTitle
            setPopupImageClass()
            gsap.to(popupI, { opacity: 1, duration: 0.3 })
          },
        })
      }

      Listeners(popupPrev, "a", "click", (e) => {
        if (currentIdx > 0) {
          currentIdx--
          const image = images[currentIdx]
          const title = image.getAttribute("data-t")
          const imgSrc = R.G.tag("img", image)[0].src
          animateImageChange(imgSrc, title)
        }
      })

      Listeners(popupNext, "a", "click", (e) => {
        if (currentIdx < images.length - 1) {
          currentIdx++
          const image = images[currentIdx]
          const title = image.getAttribute("data-t")
          const imgSrc = R.G.tag("img", image)[0].src
          animateImageChange(imgSrc, title)
        }
      })

      const close = R.G.class("cl")[0]
      Listeners(close, "a", "click", (e) => {
        e.stopImmediatePropagation()

        gsap.to(popup, {
          autoAlpha: 0,
          duration: 0.3,
          onComplete: () => {
            popup.classList.remove("_is")

            gsap.to(_A.nav, {
              autoAlpha: 1,
              pointerEvents: "auto",
              duration: 0.1,
            })
            _A.e.le.start()
          },
        })
      })

      categories.forEach((cat, idx) => {
        Listeners(cat, "a", "click", (event) => {
          if (!categories.length) return

          categories[activeI].classList.remove("_is")
          event.target.classList.add("_is")
          activeI = idx

          const filterValue = event.target.getAttribute("data-f")
          iso.arrange({ filter: `[data-c*="${filterValue}"]` })
        })
      })
    })
  }

  debounce(func, wait) {
    let timeout
    return function () {
      const context = this
      const args = arguments
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(context, args), wait)
    }
  }
}
