import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default class FullImageGallery {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((i, idx) => {
      const sections = R.G.class("s", i)

      sections.forEach((section, index) => {
        const imgW = R.G.class("fg1-m", section)[0]
        const img = R.G.tag("picture", imgW)[0]

        gsap.fromTo(
          img,
          {
            yPercent: -15,
          },
          {
            yPercent: 15,
            ease: "none",
            scrollTrigger: {
              trigger: section,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        )
      })
      if (idx === R.G.class(this.item).length - 1) {
        setTimeout(() => {
          ScrollTrigger.refresh()
        }, 1000)
      }
    })
  }
}
