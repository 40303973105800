import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import { BM, Listeners } from "utils/dom"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default class FullVideoGallery {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["pop"])

    this.h = new Map()
    this.status = false

    R.G.class(this.item).forEach((i) => {
      const sections = R.G.class("s", i)

      sections.forEach((section) => {
        const vidW = R.G.class("v", section)[0]
        const vid = R.G.class("v-p", vidW)[0]
        const img = R.G.tag("picture", vidW)[0]
        const content = R.G.class("c", section)[0]
        const toggle = R.G.class("vb", content)[0]

        this.toggleTextMore = R.G.class("more", toggle)[0].innerHTML
        this.toggleTextLess = R.G.class("less", toggle)[0].innerHTML

        toggle.textContent = this.toggleTextMore

        const contentDescription = R.G.class("d", content)[0]

        this.h.set(
          contentDescription,
          contentDescription.getBoundingClientRect().height
        )

        gsap.set(contentDescription, { height: 0, opacity: 0 })

        const gallery = R.G.class("g", section)[0]

        gsap.set(gallery, { yPercent: 100 })

        if (vid) {
          gsap.fromTo(
            vid,
            {
              yPercent: -25,
            },
            {
              yPercent: 25,
              ease: "none",
              scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
              },
            }
          )

          vid.addEventListener("canplay", () => {
            ScrollTrigger.refresh()
          })
        } else {
          gsap.fromTo(
            img,
            {
              yPercent: -25,
            },
            {
              yPercent: 25,
              ease: "none",
              scrollTrigger: {
                trigger: section,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
              },
            }
          )
        }

        if (toggle) {
          Listeners(toggle, "a", "click", this.pop)
        }
      })
    })
  }

  pop(event) {
    let isNavScrollUp = null

    const target = event.currentTarget
    const section = target.closest(".s")

    const video = R.G.class("v-p", section)[0]
    const img = R.G.tag("picture", section)[0]

    const content = R.G.class("c", section)[0]
    const contentDescription = R.G.class("d", content)[0]
    const originalHeight = this.h.get(contentDescription) || 0

    const gallery = R.G.class("g", section)[0]
    const galleryList = R.G.class("g-i", gallery)

    if (!this.tl) {
      this.tl = gsap.timeline({ paused: true })

      if (video) {
        this.tl.fromTo(
          video,
          { clipPath: "inset(0% 0px 0% 0px)", scale: 1 },
          {
            duration: 1,
            scale: 1.1,
            ease: "Power3.easeInOut",
            clipPath: "inset(0% 0px 100% 0px)",
          },
          0
        )
      } else {
        this.tl.fromTo(
          img,
          { clipPath: "inset(0% 0px 0% 0px)", scale: 1 },
          {
            duration: 1,
            scale: 1.1,
            ease: "Power3.easeInOut",
            clipPath: "inset(0% 0px 100% 0px)",
          },
          0
        )
      }

      this.tl.to(
        contentDescription,
        {
          height: `${originalHeight}px`,
          duration: 1,
        },
        0
      )

      this.tl.to(
        contentDescription,
        {
          opacity: 0.6,
          duration: 0.5,
        },
        0.5
      )

      this.tl.fromTo(
        gallery,
        { yPercent: 100 },
        {
          yPercent: 0,
          duration: 0,
        },
        0
      )

      this.tl.fromTo(
        galleryList,
        { yPercent: 10, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          stagger: 0.2,
          ease: "linear",
          duration: 0.4,
        }
      )
    }

    if (this.status === false) {
      this.tl.play()
      this.status = true

      _A.pop = true

      if (_A.nav && !_A.nav.classList.contains("_is")) {
        isNavScrollUp = true
        _A.nav.classList.add("_is")
      }

      _A.e.le.stop()

      gsap.to(window, {
        scrollTo: { y: section.offsetTop },
      })

      target.textContent = this.toggleTextLess
    } else {
      const miniTl = gsap.timeline()
      miniTl.to(contentDescription, {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.tl.killTweensOf(contentDescription)
          contentDescription.style.opacity = "0"

          this.tl.reverse()
          this.tl = null
          this.status = false
          _A.pop = false
        },
      })
      miniTl.to(
        contentDescription,
        {
          height: 0,
          duration: 1,
        },
        0.5
      )

      if (isNavScrollUp && _A.nav) {
        _A.nav.classList.remove("_is")
      }

      target.textContent = this.toggleTextMore

      _A.e.le.start()
    }
  }
}
