import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"

import { BM, Listeners } from "utils/dom"
import { io4, o1 } from "utils/easings"

gsap.registerPlugin(ScrollTrigger)

export default class HalfImageSlider {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    BM(this, ["cat", "slide"])

    this.sC = null

    this.w0 = R.G.class("w-0", this.item)
    this.w1 = R.G.class("w-1", this.item)

    this.currentIdx = 0

    this.isAnimating = false

    const w0Cat = R.G.class("w-0-c ", this.w0)

    const w1Nav = R.G.class("w-1-1", this.w1)
    const w1Media = R.G.class("w-1-0", this.w1)
    const w1Content = R.G.class("w-0-1", this.w1)

    const c = Array.from(w1Content[0].children).find((el) =>
      el.classList.contains("iss")
    )

    const m = Array.from(w1Media[0].children).find((el) =>
      el.classList.contains("iss")
    )

    const t = Array.from(w1Nav[0].children).find((el) =>
      el.classList.contains("iss")
    )

    this.sS = { c, m, t }

    w0Cat.forEach((category) => {
      Listeners(category, "a", "click", this.cat)
    })

    R.G.class("w-1-nav").forEach((nav) => {
      const prev = R.G.class("prev", nav)[0]
      const next = R.G.class("next", nav)[0]

      Listeners(prev, "a", "click", this.slide)
      Listeners(next, "a", "click", this.slide)
    })
  }

  remove(element, parentOnly) {
    element.classList.remove("iss")
    if (!parentOnly) {
      Array.from(element.children).forEach((child) =>
        child.classList.remove("iss")
      )
    }
  }

  add(element, parentOnly) {
    element.classList.add("iss")
    if (element.children.length > 0 && !parentOnly) {
      element.children[0].classList.add("iss")
    }
  }

  cat(event) {
    const tl = gsap.timeline()

    const target = event.currentTarget
    const targetWrapper = target.closest(".w-0-0")

    const w0CWrapper = R.G.class("w-0-g ", this.w0)
    const w1MWrapper = R.G.class("w-1-m", this.w1)
    const w1TWrapper = R.G.class("w-1-nav", this.w1)

    const newIdx = Array.from(targetWrapper.children).indexOf(target)
    const prevIdx = Array.from(w0CWrapper).findIndex((el) =>
      el.classList.contains("iss")
    )

    const w1TNext = R.G.class("counter-item", w1TWrapper[newIdx])
    const w1TPrev = R.G.class("counter-item", w1TWrapper[prevIdx])

    const currContent = R.G.class("ha-g-c", w0CWrapper[prevIdx])
    // const currThumb = R.G.class("ha-g-th", w1TWrapper[prevIdx])
    const currMedia = R.G.class("ha-g-m", w1MWrapper[prevIdx])

    const direction = newIdx > prevIdx

    const clickableElements = R.G.class("w-0-c", this.w0)
    clickableElements.forEach((c) => (c.style.pointerEvents = "none"))
    w1TNext.forEach((c) => (c.style.pointerEvents = "none"))

    R.G.class("w-0-c ", this.w0).forEach((c) => this.remove(c, true))
    if (prevIdx !== -1) {
      const prevW0CWrapper = w0CWrapper[prevIdx]
      const prevW1MWrapper = w1MWrapper[prevIdx]
      const prevW1TWrapper = w1TWrapper[prevIdx]

      let prevArr = [prevW0CWrapper, prevW1TWrapper, prevW1MWrapper]

      tl.to(
        prevW0CWrapper,
        {
          opacity: 0,
          duration: 0.3,
        },
        0
      )

      tl.to(
        [
          R.G.class("t", prevW0CWrapper),
          R.G.class("_b", prevW0CWrapper),
          R.G.class("d", prevW0CWrapper),
          R.G.class("b", prevW0CWrapper),
          prevW0CWrapper,
        ],
        {
          opacity: 0,
          duration: 0.3,
        },
        0
      )

      tl.fromTo(
        prevW1MWrapper,
        { clipPath: "inset(0% 0% 0% 0%)" },
        {
          duration: 1,
          ease: io4,
          clipPath: direction ? "inset(0% 0% 100% 0%)" : "inset(100% 0% 0% 0%)",
        },
        0
      )

      tl.to(
        R.G.class("media", prevW1MWrapper),
        {
          duration: 1,
          ease: io4,
          scale: 1.1,
        },
        0
      )

      tl.fromTo(
        w1TPrev,
        { clipPath: "inset(0% 0% 0% 0%)" },
        {
          duration: 0.8,
          ease: io4,
          stagger: 0.2,
          clipPath: direction ? "inset(0% 0% 100% 0%)" : "inset(100% 0% 0% 0%)",
        },
        0
      )

      tl.to(
        w1TPrev,
        {
          duration: 0.8,
          ease: io4,
          stagger: 0.1,
          scale: 1.1,
          onComplete: () => {
            gsap.set(prevW1MWrapper, { zIndex: 1 })
            prevArr.forEach((o) => {
              this.remove(o)
            })

            w1TNext[0].textContent = "01"
          },
        },
        0
      )
    }

    const newW0CWrapper = w0CWrapper[newIdx]
    const newW1MWrapper = w1MWrapper[newIdx]
    const newW1TWrapper = w1TWrapper[newIdx]

    gsap.set(newW1MWrapper, { zIndex: 2 })

    let newArr = [newW0CWrapper, newW1MWrapper, newW1TWrapper]

    this.add(target, true)

    newArr.forEach((o) => {
      this.add(o)
    })

    gsap.set(
      [
        newW0CWrapper,
        R.G.class("t", newW0CWrapper),
        R.G.class("_b", newW0CWrapper),
        R.G.class("d", newW0CWrapper),
        R.G.class("b", newW0CWrapper),
      ],
      { opacity: 0 }
    )

    tl.fromTo(
      newW1MWrapper,
      { clipPath: direction ? "inset(100% 0% 0% 0%)" : "inset(0% 0% 100% 0%)" },
      {
        duration: 1,
        ease: io4,
        clipPath: "inset(0% 0px 0% 0px)",
        onComplete: () => {
          gsap.set(newW1MWrapper, { zIndex: 2 })
        },
      },
      0
    )

    gsap.set(R.G.class("media", newW1MWrapper), { scale: 1.1 })

    tl.to(
      R.G.class("media", newW1MWrapper),
      {
        duration: 1,
        ease: io4,
        scale: 1,
      },
      0
    )

    tl.fromTo(
      w1TNext,
      { clipPath: direction ? "inset(100% 0% 0% 0%)" : "inset(0% 0% 100% 0%)" },
      {
        duration: 1,
        ease: io4,
        stagger: 0.1,
        clipPath: "inset(0% 0px 0% 0px)",
      },
      1
    )
    tl.to(
      w1TNext,
      {
        duration: 0.5,
        ease: io4,
        scale: 1,
      },
      1
    )

    tl.to(
      [
        newW0CWrapper,
        R.G.class("t", newW0CWrapper),
        R.G.class("_b", newW0CWrapper),
        R.G.class("d", newW0CWrapper),
        R.G.class("b", newW0CWrapper),
      ],
      {
        stagger: 0.04,
        opacity: 1,
        ease: o1,
        duration: 0.5,
        onComplete: () => {
          clickableElements.forEach((c, i) => {
            if (i !== newIdx) c.style.pointerEvents = "auto"
          })

          w1TNext.forEach((c, i) => {
            if (i !== 0) c.style.pointerEvents = "auto"
          })

          currContent.forEach((curr, index) => {
            if (curr.getAttribute("style")) curr.removeAttribute("style")
            if (currMedia[index].getAttribute("style"))
              currMedia[index].removeAttribute("style")
          })

          this.sS = { c: newW0CWrapper, m: newW1MWrapper, t: newW1TWrapper }

          const activeHaGmElements = Array.from(R.G.class("ha-g-m", this.sS.m))
          this.currentIdx = activeHaGmElements.findIndex((el) =>
            el.classList.contains("iss")
          )
        },
      },
      0.8
    )
  }

  getSiblings(element) {
    return Array.from(element.parentNode.children).filter(
      (child) => child !== element
    )
  }

  setCounter(counter) {
    return counter > 9 ? counter : `0${counter}`
  }

  slide(event) {
    if (this.isAnimating) return

    this.isAnimating = true

    const tl = gsap.timeline()
    const target = event.currentTarget
    const siblings = this.getSiblings(target)

    const content = R.G.class("ha-g-c", this.sS.c)
    const media = R.G.class("ha-g-m", this.sS.m)

    const maxIdx = media.length - 1

    let newIdx
    if (target.classList.contains("next")) {
      newIdx = this.currentIdx < maxIdx ? this.currentIdx + 1 : 0
      siblings.forEach((sib) => {
        if (sib.classList.contains("prev")) {
          sib.style.pointerEvents = "none"
        } else if (sib.classList.contains("counter")) {
          R.G.class("curr", sib)[0].textContent = this.setCounter(newIdx + 1)
        }
      })
    } else {
      newIdx = this.currentIdx > 0 ? this.currentIdx - 1 : maxIdx
      siblings.forEach((sib) => {
        if (sib.classList.contains("next")) {
          sib.style.pointerEvents = "none"
        } else if (sib.classList.contains("counter")) {
          R.G.class("curr", sib)[0].textContent = this.setCounter(newIdx + 1)
        }
      })
    }

    const prevIdx = this.currentIdx

    const newMedia = media[newIdx]
    const newContent = content[newIdx]
    const newArr = [newMedia, newContent]

    const prevMedia = media[prevIdx]
    const prevContent = content[prevIdx]
    const prevArr = [prevMedia, prevContent]

    const direction = newIdx > prevIdx

    tl.fromTo(
      prevMedia,
      { clipPath: "inset(0% 0% 0% 0%)" },
      {
        duration: 1,
        ease: io4,
        clipPath: direction ? "inset(0% 0% 100% 0%)" : "inset(100% 0% 0% 0%)",
      },
      0
    )

    tl.to(
      R.G.class("media", prevMedia),
      {
        duration: 1,
        ease: io4,
        scale: 1.1,
        onComplete: () => {
          gsap.set(prevMedia, { zIndex: 1 })
          prevArr.forEach((item) => {
            this.remove(item, true)
          })
        },
      },
      0
    )

    tl.to(
      [
        prevContent,
        R.G.class("t", prevContent),
        R.G.class("_b", prevContent),
        R.G.class("d", prevContent),
        R.G.class("b", prevContent),
      ],
      {
        stagger: 0.1,
        opacity: 0,
        ease: o1,
        duration: 0.5,
      },
      0
    )

    newArr.forEach((item) => {
      this.add(item, true)
    })

    tl.fromTo(
      newMedia,
      { clipPath: direction ? "inset(100% 0% 0% 0%)" : "inset(0% 0% 100% 0%)" },
      {
        duration: 1,
        ease: io4,
        clipPath: "inset(0% 0px 0% 0px)",
        onComplete: () => {
          gsap.set(newMedia, { zIndex: 2 })
        },
      },
      0
    )

    gsap.set(R.G.class("media", newMedia), { scale: 1.1 })

    tl.to(
      R.G.class("media", newMedia),
      {
        duration: 1,
        ease: io4,
        scale: 1,
        onComplete: () => {
          siblings.forEach((sib) => {
            if (
              target.classList.contains("next") &&
              sib.classList.contains("prev")
            ) {
              sib.style.pointerEvents = "auto"
            } else if (sib.classList.contains("next")) {
              sib.style.pointerEvents = "auto"
            }
          })
          this.isAnimating = false
        },
      },
      0
    )

    gsap.set(
      [
        newContent,
        R.G.class("t", newContent),
        R.G.class("_b", newContent),
        R.G.class("d", newContent),
        R.G.class("b", newContent),
      ],
      { opacity: 0 }
    )

    tl.to(
      [
        newContent,
        R.G.class("t", newContent),
        R.G.class("_b", newContent),
        R.G.class("d", newContent),
        R.G.class("b", newContent),
      ],
      {
        stagger: 0.1,
        opacity: 1,
        ease: o1,
        duration: 0.5,
      },
      0.8
    )

    this.currentIdx = newIdx
  }
}
