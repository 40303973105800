import GSAP from "gsap"
import { CustomEase } from "gsap/CustomEase"

GSAP.registerPlugin(CustomEase)

export const ii = CustomEase.create("ce", ".72, 0, .11, 1")
export const i1 = CustomEase.create("i1", ".12, 0, .39, 0")
export const i2 = CustomEase.create("i1", "0.55, 0.085, 0.68, 0.53")
export const o2 = CustomEase.create("i1", "0.25, 0.46, 0.45, 0.94")
export const i3 = CustomEase.create("i3", ".32, 0, .67, 0")
export const o3 = CustomEase.create("o3", "0.215, 0.61, 0.355, 1")
export const o1 = CustomEase.create("o1", ".61, 1, .88, 1")
export const i4 = CustomEase.create("i4", "0.895, 0.03, 0.685, 0.22")
export const o4 = CustomEase.create("o4", "0.165, 0.84, 0.44, 1")
export const io4 = CustomEase.create("io4", "0.77, 0, 0.175, 1")
export const i5 = CustomEase.create("i5", "0.755, 0.05, 0.855, 0.06")
export const io5 = CustomEase.create("io5", "0.86, 0, 0.07, 1")
export const i6 = CustomEase.create("i6", "0.95, 0.05, 0.795, 0.035")
export const o6 = CustomEase.create("o6", ".16, 1, .3, 1")
export const io6 = CustomEase.create("io6", ".87, 0, .13, 1")
export const obb1 = CustomEase.create("obb1", "0, 0, 0.58, 1")
