import GSAP from "gsap"

import { o4 } from "utils/easings"

export default class ProductDesc {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section) => {
      const pics = R.G.tag("picture", section)

      pics.forEach((pic, index) => {
        const img = R.G.tag("img", pic)[0]
        GSAP.fromTo(
          img,
          { y: "1.5vw" },
          {
            y: "-1.5vw",
            ease: o4,
            stagger: 0.2,
            scrollTrigger: {
              trigger: pic,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        )
      })
    })
  }
}
