import { Listeners } from "utils/dom"

export default class MagazinesList {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section) => {
      const ul = R.G.class("ma2-li", section)

      ul.forEach((li) => {
        const img = R.G.class("_i", li)[0]
        const vid = R.G.tag("video", li)[0]

        if (R.Is.def(vid)) {
          Listeners(li, "a", "mouseover", (e) => {
            img.style.opacity = 0
            vid.play()
          })
          Listeners(li, "a", "mouseout", (e) => {
            img.style.opacity = 1

            vid.pause()
          })
        }
      })
    })
  }
}
